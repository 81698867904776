* {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #282c31;
}

.line-break {
  background: linear-gradient(90deg, #0676ff, #1dbee0);
  border-radius: 5px;
  height: 3px;
  margin: 2vh auto 1vh;
  width: 70px;
}
