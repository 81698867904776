.hero {
  height: 100vh;
  position: relative;
  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background: black;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.4;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2.5rem;
    height: 65%;
    button {
      color: #fff;
      background-color: #0676ff;
      border-radius: 0.3125rem;
      padding: 0.75rem 3rem;
      border: none;
      font-size: 1rem;

      font-weight: 400;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  &__title {
    margin: 0 1rem;

    width: 50%;
    h1 {
      text-align: center;
      color: #fff;
      font-size: 2.5rem;
    }
    h4 {
      margin-top: 1.125rem;
      color: #fff;
      font-weight: 500;
      font-size: 1.75rem;
      text-align: center;
    }
    @media (max-width: 1199px) {
      h1 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.25rem;
      }
    }
    @media (max-width: 768px) {
      width: 90%;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  margin: 0 7.5rem;
  ul {
    padding: 0;
    display: flex;
    list-style: none;
    li {
      cursor: pointer;
      padding: 1rem;
      font-size: 1rem;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  @media (max-width: 640px) {
    ul {
      margin-top: 0.5rem;
    }
    flex-direction: column;
  }
}

.rehv {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  &__wrapper {
    width: 50%;
    @media (max-width: 1024px) {
      width: 75%;
    }
    @media (max-width: 768px) {
      width: 95%;
    }
  }
}

.tables {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  h1 {
    color: #fff;
    font-size: 1.75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background: #0676ff;
  }

  .css-o4b71y-MuiAccordionSummary-content {
    color: #fff;
    justify-content: center;
  }

  .css-i4bv87-MuiSvgIcon-root {
    stroke: white;
    fill: #fff;
  }
}

.contact {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
}
.wrapper {
  margin-top: 15vh;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #fff;
  h1 {
    text-transform: uppercase;
  }
}
.content-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  box-shadow: 1px 1px 15px #000000;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.contact-wrapper {
  flex: 1;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding: 20px 20px 20px 2rem;
}

.card {
  text-align: left;
  display: flex;
  align-items: center;
}

.map-wrapper {
  flex: 1;
}

.map-style {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-icon {
  margin-right: 1rem;
}

.card-wrapper {
  background-color: #1c3f4d;
  border-radius: 5px;
  color: white;
  padding: 2rem;
}

.makeStyles-partners-2 {
  margin-top: 0px !important;
}

.MuiContainer-maxWidthLg {
  max-width: 992px !important;
}

@media screen and (max-width: 1024px) {
  .content-container {
    width: 95%;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 578px) {
  .MuiGrid-justify-content-xs-flex-end {
    justify-content: start !important;
  }
}

.footer {
  padding: 48px;
  margin-top: 5rem;
  background-color: #17191c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  h6 {
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  &__grid {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 3rem;
      width: 100%;
    }
  }
  &__about {
    width: calc(100% + 32px);
  }
  &__partners {
    display: flex;
    align-items: end;
    flex-direction: column;
    @media (max-width: 1024px) {
      align-items: start;
    }
    ul {
      list-style: none;
      padding: 0;
    }
  }
}
.dynamic {
  margin: 0rem 2rem;
  color: white;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2.125rem;
}